import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttButton, SttGrid, SttTranslateHook } from '@stt-componentes/core';
import { useSignals } from '@preact/signals-react/runtime';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    iframeContainer: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '20px',
        '@media (max-width: 992px)': {
            width: '100%'
        }
    },
    iframe: {
        width: '100%',
        height: '800px',
        border: 'none'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px'
    },
    botaoVoltar: {
        marginBottom: theme.spacing(3)
    }
}));

const Iframe = ({ contexto, url }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
    }, [url?.value]);

    const voltar = () => {
        url.value = null;
        navigate(`/${contexto.caminho}`, { replace: true });
    };

    return (
        <SttGrid container spacing={3} className={classes.iframeContainer}>
            <SttGrid item xs={12}>
                {loading && (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                )}
                <iframe
                    className={classes.iframe}
                    src={url.value}
                    onLoad={() => setLoading(false)}
                    onError={() => setLoading(false)}
                    style={{ display: loading ? 'none' : 'block' }}
                />
            </SttGrid>
            <SttGrid item xs={12}>
                <SttButton
                    className={classes.botaoVoltar}
                    onClick={voltar}
                    color="primary"
                    variant="contained"
                    nomarginleft
                >
                    {strings.voltar}
                </SttButton>
            </SttGrid>
        </SttGrid>
    );
};

export default Iframe;
